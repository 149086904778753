import EvoMarkdown from '@/components/Md.vue';
import EVONETMTC from '@/views/mtc/markdown/EVONETMTC.md';
import ALIPAYTC from '@/views/mtc/markdown/ALIPAYTC.md';
import WeChatPayTC from '@/views/mtc/markdown/WeChatPayTC.md';

export const mtcHomeMixin = {
  data() {
    return {
      items: [
        {
          title: 'mtc.title_1',
          link: '/mtc/evonet-mtc',
          linkText: 'read_more_upper'
        },
        {
          title: 'mtc.title_2',
          link: '/mtc/alipay-tc',
          linkText: 'read_more_upper'
        },
        {
          title: 'mtc.title_3',
          link: '/mtc/wechatpay-tc',
          linkText: 'read_more_upper'
        }
      ]
    };
  },
  methods: {
    goTo(link) {
      this.$router.push(link).catch(() => {});
    }
  }
};

export const mtcDetailMixin = {
  components: {
    EvoMarkdown
  },
  data: function () {
    return {
      itemsMap: {
        EVONETMTC: {
          title: 'mtc.privacy_policy',
          desc: 'mtc.title_1',
          link: '/mtc/evonet-mtc',
          md: EVONETMTC
        },
        ALIPAYTC: {
          title: 'mtc.privacy_policy',
          desc: 'mtc.title_2',
          link: '/mtc/alipay-tc',
          md: ALIPAYTC
        },
        WeChatPayTC: {
          title: 'mtc.privacy_policy',
          desc: 'mtc.title_3',
          link: '/mtc/wechatpay-tc',
          md: WeChatPayTC
        }
      }
    };
  },
  computed: {
    selectedItem() {
      const name = this.$route.name;
      return this.itemsMap[name] || {};
    },
    md() {
      return this.selectedItem.md;
    },
    items() {
      const name = this.$route.name;
      return Object.keys(this.itemsMap)
        .filter(key => key !== name)
        .map(key => this.itemsMap[key]);
    },
    breadcrumbs() {
      return [
        {
          text: this.$t('mtc.title'),
          disabled: false,
          href: '/mtc'
        },
        {
          text: this.$t(this.selectedItem.desc),
          disabled: true
        }
      ];
    }
  }
};